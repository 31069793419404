/*
 * jQuery FlexSlider v2.6.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 *
 */

/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/
.lae-flex-container a:hover,
.lae-flex-slider a:hover {
    outline: none;
    }
.lae-slides,
.lae-slides > li,
.lae-flex-control-nav,
.lae-flex-direction-nav {
    margin: 0;
    padding: 0;
    list-style: none;
    }
.lae-flex-pauseplay span {
    text-transform: capitalize;
    }
/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
.lae-flexslider {
    margin: 0;
    padding: 0;
    }
.lae-flexslider .lae-slides > li {
    display: none;
    -webkit-backface-visibility: hidden;
    }
.lae-flexslider .lae-slides img {
    width: 100%;
    display: block;
    }
html[xmlns] .lae-flexslider .lae-slides {
    display: block;
    }
* html .lae-flexslider .lae-slides {
    height: 1%;
    }
.no-js .lae-flexslider .lae-slides > li:first-child {
    display: block;
    }
/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
.lae-flexslider {
    margin: 0;
    position: relative;
    zoom: 1;
    }
.lae-flexslider .lae-slides {
    zoom: 1;
    overflow: hidden;
    }
.lae-flexslider .lae-slides img {
    height: auto;
    -moz-user-select: none;
    }
.lae-flex-viewport {
    max-height: 2000px;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    }
.loading .lae-flex-viewport {
    max-height: 300px;
    }
.carousel li {
    margin-right: 5px;
    }
.lae-flex-direction-nav {
    *height: 0;
    }
.lae-flex-direction-nav a {
    text-decoration: none;
    display: block;
    width: 40px;
    height: 40px;
    margin: -20px 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.8);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    }
a.lae-flex-prev {
    /* font-family: "flexslider-icon"; */
    font-size: 40px;
    /* display: inline-block; */
    /* content: '\f001'; */
    color: rgba(0, 0, 0, 0.8);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
    }
.lae-flex-direction-nav a.lae-flex-next:before {
    content: '\f002';
    }
.lae-flex-direction-nav .lae-flex-prev {
    left: -50px;
    }
.lae-flex-direction-nav .lae-flex-next {
    right: -50px;
    text-align: right;
    }
.lae-flex-direction-nav .lae-flex-disabled {
    opacity: 0!important;
    filter: alpha(opacity=0);
    cursor: default;
    z-index: -1;
    }
.lae-flex-pauseplay a {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 5px;
    left: 10px;
    opacity: 0.8;
    z-index: 10;
    overflow: hidden;
    cursor: pointer;
    color: #000;
    }
.lae-flex-pauseplay a:before {
    font-family: "flexslider-icon";
    font-size: 20px;
    display: inline-block;
    content: '\f004';
    }
.lae-flex-pauseplay a:hover {
    opacity: 1;
    }
.lae-flex-pauseplay a.lae-flex-play:before {
    content: '\f003';
    }
.lae-flex-control-nav {
    width: 100%;
    position: absolute;
    bottom: -40px;
    text-align: center;
    }
.lae-flex-control-nav li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
    *display: inline;
    }
.lae-flex-control-paging li a {
    width: 11px;
    height: 11px;
    display: block;
    background: #666;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    text-indent: -9999px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    }
.lae-flex-control-paging li a:hover {
    background: #333;
    background: rgba(0, 0, 0, 0.7);
    }
.lae-flex-control-paging li a.lae-flex-active {
    background: #000;
    background: rgba(0, 0, 0, 0.9);
    cursor: default;
    }
.lae-flex-control-thumbs {
    margin: 5px 0 0;
    position: static;
    overflow: hidden;
    }
.lae-flex-control-thumbs li {
    width: 25%;
    float: left;
    margin: 0;
    }
.lae-flex-control-thumbs img {
    width: 100%;
    height: auto;
    display: block;
    opacity: .7;
    cursor: pointer;
    -moz-user-select: none;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    }
.lae-flex-control-thumbs img:hover {
    opacity: 1;
    }
.lae-flex-control-thumbs .lae-flex-active {
    opacity: 1;
    cursor: default;
    }
/* ====================================================================================================================
 * RESPONSIVE
 * ====================================================================================================================*/
@media screen and (max-width: 860px) {
    .lae-flex-direction-nav .lae-flex-prev {
        opacity: 1;
        left: 10px;
        }
    .lae-flex-direction-nav .lae-flex-next {
        opacity: 1;
        right: 10px;
        }
    }


/* ---------------------------------------- Slick Slider ----------------------------------------- */

/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    }

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
    }
.slick-list:focus
{
    outline: none;
    }
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
    }

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    }

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    }
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
    }
.slick-track:after
{
    clear: both;
    }
.slick-loading .slick-track
{
    visibility: hidden;
    }

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
    }
[dir='rtl'] .slick-slide
{
    float: right;
    }
.slick-slide img
{
    display: block;
    }
.slick-slide.slick-loading img
{
    display: none;
    }
.slick-slide.dragging img
{
    pointer-events: none;
    }
.slick-initialized .slick-slide
{
    display: block;
    }
.slick-loading .slick-slide
{
    visibility: hidden;
    }
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
    }
.slick-arrow.slick-hidden {
    display: none;
    }
